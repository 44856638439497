export function getCalendarPrices(dateFrom, dateTo) {
    return fetch(`${process.env.REACT_APP_BOOKING_API}/calendar/prices/get`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            dateFrom,
            dateTo,
        }),
    })
        .then(response => response.json())
        .then(data => {
            if (data.success) {
                return data
            } else {
                throw new Error("Ошибка при загрузке цен.")
            }
        })
}