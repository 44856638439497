.weeks {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
}

.week {
    font-weight: 300;
    font-size: 12px;
    text-transform: uppercase;
    color: var(--color-gray);
    display: grid;
    place-items: center;
    margin: 12px 0;
}

.week:nth-child(6n),
.week:nth-child(7n) {
    color: red;
}

.days {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
}

.months {
    display: flex;
    gap: 12px;
}

.day {
    width: 60px;
    height: 60px;
    border: none;
    cursor: pointer;
    display: flex;
    background: none;
    flex-direction: column;
    align-items: center;
    font-size: 15px;
    font-weight: 500;
    justify-content: space-between;
    padding: 6px;
    transition: background .2s ease;
    color: var(--color-gray);
    position: relative;
    -webkit-appearance: none;
    -webkit-box-shadow: 1px 1px 0 0 var(--color-lilac), inset 1px 0 0 0 var(--color-lilac), inset 0 1px 0 0 var(--color-lilac);
    box-shadow: 1px 1px 0 0 var(--color-lilac), inset 1px 0 0 0 var(--color-lilac), inset 0 1px 0 0 var(--color-lilac);
    box-sizing: border-box;
    font-family: Roboto;
    padding-top: 10px;
}

.day svg {
    width: 21px;
    height: 21px;
}

.monthName {
    text-align: center;
    margin: 12px 0;
    font-weight: 500;
    text-transform: capitalize;
    font-size: 16px;
}

.container {
    position: relative;
}

.arrows {
    position: absolute;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.arrows button {
    all: unset;
    cursor: pointer;
    color: var(--color-gray);
    padding: 12px;
}

.arrows button svg {
    width: 19px;
    height: 19px;
}

.arrows button:disabled {
    color: var(--color-lilac);
}

.wrapper {
    background: var(--color-white);
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    border-radius: 4px;
    -webkit-appearance: none;
    box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
    -webkit-box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
    max-width: 900px;
}

/* modifiers */

.hovered {
    background: var(--color-lilac-dark);
    color: var(--color-white);
}

.from {
    background: var(--color-gray);
    color: var(--color-white);
}

.to {
    background: var(--color-gray);
    color: var(--color-white);
}

.inRange {
    background: var(--color-gray);
    color: var(--color-white);
}

.disabled {
    cursor: default;
    color: var(--color-lilac);
    box-shadow: none;
    -webkit-box-shadow: none;
}

.cheapest:not(.from):not(.to):not(.inRange):not(.hovered) {
    color: var(--color-lilac-dark);
}

.cheapest:not(.from):not(.to):not(.inRange):not(.hovered) span {
    color: #3bbf56;
}

@keyframes spin {
    100% {
        transform: rotate(360deg)
    }
}

.loading svg {
    animation: 3s infinite linear spin;
}

@media (min-width: 769px) {

    .day:hover,
    .cheapest:not(.from):not(.to):not(.inRange):not(.hovered):hover {
        background: var(--color-lilac-dark);
        color: var(--color-white);
    }

    .cheapest:not(.from):not(.to):not(.inRange):not(.hovered):hover span {
        color: var(--color-white);
    }

    .disabled:hover {
        background: none;
        color: var(--color-lilac);
    }

    .inRange:hover,
    .to:hover,
    .from:hover {
        background: var(--color-gray);
        color: var(--color-white);
    }
}

.infoText {
    color: var(--color-gray);
    margin: 0;
    padding: 0;
    font-size: 18px;
}

.description {
    font-size: 14px;
    font-weight: 300;
    color: var(--color-gray);
    margin-top: 8px;
}

.title {
    color: var(--color-gray);
    font-size: 18px;
    font-weight: 500;
}

.day span {
    white-space: nowrap;
    font-size: 10px;
    font-weight: 400;
}

/* modifiers */

@media (max-width: 988px) {
    .day {
        width: 50px;
        height: 50px;
        font-size: 12px;
        padding: 4px;
        padding-top: 8px;
    }

    .wrapper {
        max-width: 760px;
    }

    .day span {
        font-size: 9px;
    }
}

@media (max-width: 768px) {
    .wrapper {
        padding: 16px;
        max-height: 80vh;
        min-height: 400px;
    }

    .container {
        overflow-y: auto;
        padding: 0;
        padding-right: 1px;
        padding-bottom: 1px;
    }

    .day {
        width: 50px;
        height: 50px;
        font-size: 12px;
    }

    .months {
        flex-direction: column;
    }

    .day svg {
        width: 18px;
        height: 18px;
    }

    .arrows {
        display: none;
    }
}


.popover {
    display: none;
    color: var(--color-black);
    z-index: 1;
    background: var(--color-white);
    padding: 6px;
    border-radius: 4px;
    font-size: 12px;
    width: 160px;
    border: 1px solid #e6e6e6;
    font-weight: 300;
    position: absolute;
    top: 0;
    transform: translateY(calc(-100% - 6px));
    /* box-shadow: 0 1px 14px rgba(0, 0, 0, .2); */
}

.popover::before {
    content: "";
    position: absolute;
    bottom: calc(100cqw / 995 * -6);
    left: calc(50% - 6px);
    width: 10px;
    height: 10px;
    transform: rotate(45deg);
    border-radius: 1px;
    background: var(--color-white);
    box-shadow: 0 1px 14px rgba(0, 0, 0, .2);
    border: 1px solid #e6e6e6;
}

.popover::before {
    content: "";
    position: absolute;
    bottom: -6px;
    left: calc(50% - 6px);
    width: 10px;
    height: 10px;
    transform: rotate(45deg);
    border-radius: 1px;
    background: var(--color-white);
    box-shadow: 0 1px 14px rgba(0, 0, 0, .2);
}

.popover::after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    background: var(--color-white);
    padding: 6px;
    border-radius: 4px;
    color: var(--color-gray);
}


.day:hover .popover {
    display: block;
}

.beforeTo {
    opacity: 0.5;
}

.beforeMin,
.afterMax,
.departureDisabled {
    opacity: .3 !important;
}