import { format, getWeek } from "date-fns";
import styles from "./style.module.css";
import { createContext, useCallback, useContext, useEffect, useRef, useState } from "react";
import { ArrowLeftIcon, ArrowRightIcon, Cross2Icon, InfoCircledIcon } from "@radix-ui/react-icons";
import { ru } from "date-fns/locale";
import classNames from "classnames";
import { NotAvailableIcon, SpinnerIcon } from "../../UI/Icon";
import { formatPrice } from "../../utils/formatPrice";
import { localeNight } from "../../utils/locale/locale";
import useScreenSize from "../../hooks/useScreenSize";

const CalendarContext = createContext();

export function Calendar({
    range,
    setRange,
    month,
    setMonth,
    monthFrom,
    numberOfMonths,
    setNumberOfMonths,
    mode,
    setMode,
    prices,
    uploadedMonth,
    clickHandler,
    title,
    description,
    timeSettings,
    shadow = true,
    className,
    closedForArrival = [],
    closedForDeparture = [],
    isTourCalendar = false,
    // minmax = [{ date: "2024-12-10", min: 3, max: 3 }]
    minmax = []
}) {
    const renderMonths = useCallback(() => {
        const months = [];
        for (let i = 0; i < numberOfMonths; i++) {
            const date = new Date(new Date(new Date(month).setMonth(month.getMonth() + i)).setDate(1));
            months.push(<Month key={i} dispalyMonth={date} />);
        }

        return months;
    }, [month, numberOfMonths]);

    const [renderedMonths, setRenderedMonths] = useState(renderMonths());

    useEffect(() => {
        setRenderedMonths(renderMonths())
    }, [renderMonths])

    function prevMonth() {
        setMonth(new Date(new Date(month).setMonth(new Date(month).getMonth() - 1)))
    }

    function nextMonth() {
        setMonth(new Date(new Date(month).setMonth(new Date(month).getMonth() + 1)))
    }

    const [hovered, setHovered] = useState(null);

    const [localPrices, setLocalPrices] = useState(new Map());
    const [closeDeparture, setCloseDeparture] = useState(null);
    const [infoText, setInfoText] = useState(null);

    const [smallestPrices, setSmallestPrices] = useState(new Map());

    // БАГ с closeDeparture

    const getInfoText = () => {
        if (infoText) {
            return infoText;
        } else {
            if (mode === "from") {
                return "Выберите дату заезда"
            } else {
                if (range.from && localPrices.get(format(range.from, "yyyy-MM-dd"))) {
                    return `Заезд ${format(range.from, "dd MMMM", { locale: ru })}. Выберите дату выезда`;
                } else {
                    const dayBeforeFrom = new Date(range.from.getTime() - 24 * 60 * 60 * 1000);
                    const today = new Date(new Date().setHours(0, 0, 0, 0));

                    if (today >= dayBeforeFrom && localPrices.get(format(dayBeforeFrom, "yyyy-MM-dd"))) {
                        return <div style={{ color: "red" }}>Возможен только выезд</div>;
                    } else {
                        return "Проживание невозможно";
                    }
                }
            }
        }
    }

    const getSmallestPrices = useCallback((localPrices) => {
        const smallestPrices = {}

        for (let date of localPrices.keys()) {
            if (new Date(new Date(date).setHours(0, 0, 0, 0)).getTime() < new Date(new Date().setHours(0, 0, 0, 0)).getTime()) {
                continue;
            }

            const month = new Date(new Date(date).setDate(1));
            const formatMonth = format(month, "yyyy-MM-dd");
            const datePrice = localPrices.get(date);
            const weekNumber = getWeek(new Date(date), {
                weekStartsOn: 1
            })

            if (`${formatMonth}-${weekNumber}` in smallestPrices) {
                if (smallestPrices[`${formatMonth}-${weekNumber}`] > datePrice && datePrice !== 0) {
                    smallestPrices[`${formatMonth}-${weekNumber}`] = datePrice
                }
            } else {
                smallestPrices[`${formatMonth}-${weekNumber}`] = datePrice
            }
        }

        return smallestPrices
    }, [])

    // 'этштквзвтбг'
    useEffect(() => {
        let lastPrice = Array.from(prices).sort((a, b) => new Date(a[0]).getTime() - new Date(b[0]).getTime()).filter(i => i[1] !== null);
        lastPrice = lastPrice[lastPrice.length - 1]
        if (lastPrice && lastPrice[1] !== 0 && mode === "to") {
            const newLocalPrices = new Map(prices).set(format(new Date(new Date(new Date(lastPrice[0]).getTime() + (24 * 60 * 60 * 1000)).setHours(0, 0, 0, 0)), "yyyy-MM-dd"), 0)
            setLocalPrices(newLocalPrices);
            setSmallestPrices(getSmallestPrices(newLocalPrices))
        } else {
            const newLocalPrices = new Map(prices)
            setLocalPrices(new Map(prices));
            setSmallestPrices(getSmallestPrices(newLocalPrices))
        }
    }, [prices, mode, getSmallestPrices]);

    useEffect(() => {
        if (mode === 'from') {
            setCloseDeparture(null)
        } else if (mode === "to") {
            let arr = Array.from(localPrices).sort((a, b) => new Date(a[0]).getTime() - new Date(b[0]).getTime())
            arr = arr.filter(i => i[1] !== null);
            const index = arr.findIndex(i => i[0] === format(range.from, "yyyy-MM-dd"));
            const date = arr.slice(index).find(i => i[1] === 0);

            if (date) {
                setCloseDeparture(new Date(new Date(date[0]).setHours(0, 0, 0, 0)));
            }
        }
    }, [range, mode, localPrices, prices]);

    const calendarContainerRef = useRef();

    function scrollHandler(e) {
        if (e.target.scrollHeight - e.target.offsetHeight - e.target.scrollTop <= 50) {
            calendarContainerRef.current.removeEventListener("scroll", scrollHandler);
            setNumberOfMonths(numberOfMonths += 2);
        }
        return () => {
            calendarContainerRef.current.addEvenetListener("scroll", scrollHandler);
        }
    }

    return (
        <CalendarContext.Provider value={{
            range,
            setRange,
            mode,
            setMode,
            hovered,
            setHovered,
            localPrices,
            uploadedMonth,
            closeDeparture,
            clickHandler,
            setInfoText,
            smallestPrices,
            closedForArrival,
            closedForDeparture,
            isTourCalendar,
            minmax
        }}>
            <div className={classNames(styles.calendar, className, shadow ? "" : styles.withoutShadow)} onClick={e => e.stopPropagation()}>
                <div className={styles.wrapper}>
                    {title && <div className={styles.title}>{title}</div>}
                    <div className={styles.container} ref={calendarContainerRef} onScroll={scrollHandler}>
                        <div className={styles.arrows}>
                            <button type="button" onClick={prevMonth} disabled={monthFrom && (monthFrom.getTime() < month.getTime() ? false : true)}><ArrowLeftIcon /></button>
                            <button type="button" onClick={nextMonth}><ArrowRightIcon /></button>
                        </div>
                        <div className={styles.months}>
                            {renderedMonths}
                        </div>
                    </div>
                    <div>
                        <div className={styles.infoText}>{getInfoText()}</div>
                        <div className={styles.description}>{description} Заезд не ранее {timeSettings.time_from}, выезд не позднее {timeSettings.time_to} по местному времени.</div>
                    </div>
                </div>
            </div>
        </CalendarContext.Provider >
    )
}


function Month({ dispalyMonth }) {
    const renderDays = useCallback(() => {
        const days = [];

        const fromDay = dispalyMonth.getDay();
        const fromDate = dispalyMonth.getDate();
        const firstDate = new Date(new Date(dispalyMonth).setDate(fromDay > 0 ? fromDate - fromDay + 1 : fromDate - 6));

        const dateTo = new Date(new Date(new Date(dispalyMonth).setMonth(dispalyMonth.getMonth() + 1)).setDate(0));
        const toDay = dateTo.getDay();
        const toDate = dateTo.getDate();
        const lastDate = new Date(new Date(dateTo).setDate(toDay > 0 ? toDate + 7 - toDay : toDate));
        const count = ((lastDate - firstDate) / 1000 / 24 / 60 / 60 + 1);

        for (let i = 0; i < count; i++) {
            let date = new Date(new Date(firstDate.getTime() + (i * 24 * 60 * 60 * 1000)).setHours(0, 0, 0, 0));

            days.push(<Day key={date.getTime()} day={date} dispalyMonth={dispalyMonth} />)
        }

        return days;
    }, [dispalyMonth]);

    const [renderedDays, setRenderedDays] = useState(renderDays())

    useEffect(() => {
        setRenderedDays(renderDays())
    }, [renderDays])

    const { setHovered } = useContext(CalendarContext);

    function mouseOutHandler() {
        setHovered(null)
    }

    return (
        <div>
            <div className={styles.monthName}>{format(dispalyMonth, "LLLL yyyy", { locale: ru })}</div>
            <div className={styles.month}>
                <div className={styles.weeks}>
                    <div className={styles.week}>Пн</div>
                    <div className={styles.week}>Вт</div>
                    <div className={styles.week}>Ср</div>
                    <div className={styles.week}>Чт</div>
                    <div className={styles.week}>Пт</div>
                    <div className={styles.week}>Сб</div>
                    <div className={styles.week}>Вс</div>
                </div>
                <div className={styles.days} onMouseOut={mouseOutHandler}>
                    {renderedDays}
                </div>
            </div>
        </div>
    )
}

function Day({ day, dispalyMonth }) {
    const { mode, range, hovered, setHovered, localPrices, uploadedMonth, closeDeparture, clickHandler, setInfoText, smallestPrices, closedForArrival, closedForDeparture, isTourCalendar, minmax } = useContext(CalendarContext);

    const weekNumber = getWeek(day, {
        weekStartsOn: 1
    })

    const smallestPrice = smallestPrices[`${format(dispalyMonth, "yyyy-MM-dd")}-${weekNumber}`];

    const isOutside = day.getMonth() !== dispalyMonth.getMonth();
    const size = useScreenSize();

    function mouseOverHandler(text = "") {
        if (size.width < 768) return

        if (true) {
            setHovered(day);

            const date = format(day, "yyyy-MM-dd");

            function getTextForFrom() {
                if (localPrices.get(date)) {
                    setInfoText(`Заезд ${format(day, "dd MMMM", { locale: ru })}`)
                } else {
                    const dayBeforeFrom = new Date(day.getTime() - 24 * 60 * 60 * 1000);
                    const today = new Date(new Date().setHours(0, 0, 0, 0));

                    if (today <= dayBeforeFrom && localPrices.get(format(dayBeforeFrom, "yyyy-MM-dd")) && !closedForDeparture.includes(format(day, "yyyy-MM-dd"))) {
                        setInfoText(<div style={{ color: "red" }}>Возможен только выезд</div>);
                    } else {
                        setInfoText("Проживание невозможно");
                    }
                }
            }

            if (mode === 'from') {
                getTextForFrom()
            } else {
                const dateFrom = format(range.from, 'yyyy-MM-dd');
                const dateTo = format(day, 'yyyy-MM-dd');

                const nightCount = (day.getTime() - range.from.getTime()) / 60 / 60 / 24 / 1000;

                if (dateTo === dateFrom) {
                    setInfoText(`Заезд ${format(range.from, "dd MMMM", { locale: ru })}. Выберите дату выезда`);
                    return;
                }

                if (day.getTime() - range.from.getTime() < 0) {
                    getTextForFrom();
                    return;
                }

                if (localPrices.get(dateTo) || (closeDeparture && day && day?.getTime() === closeDeparture?.getTime())) {
                    setInfoText(`Заезд ${format(range.from, "dd MMMM", { locale: ru })} — Выезд ${format(day, "dd MMMM", { locale: ru })}* (${nightCount} ${localeNight(nightCount)})`)
                } else {
                    const dayBeforeFrom = new Date(day.getTime() - 24 * 60 * 60 * 1000);
                    const today = new Date(new Date().setHours(0, 0, 0, 0));

                    if (today <= dayBeforeFrom && localPrices.get(format(dayBeforeFrom, "yyyy-MM-dd"))) {
                        setInfoText(<div style={{ color: "#ff0000" }}>Возможен только выезд</div>);
                    } else {
                        setInfoText("Проживание невозможно");
                    }
                }
            }
        }
        if (text !== "") {
            setInfoText(text);
        }
    }

    const [price, setPrice] = useState(localPrices.get(format(day, "yyyy-MM-dd")));

    useEffect(() => {
        setPrice(localPrices.get(format(day, "yyyy-MM-dd")))
    }, [localPrices, day]);

    // Дни не в месяце
    if (isOutside) {
        return <div></div>
    }

    // Прошедше дни
    if (new Date(new Date().setHours(0, 0, 0, 0)).getTime() > day.getTime()) {
        return <button type="button" className={classNames(styles.day, styles.disabled)} disabled>{format(day, "d")}</button>
    }

    const classnames = [];

    const minmaxdata = minmax.find(item => item.date === format(range.from, "yyyy-MM-dd"));

    // minmax
    if (mode === "to" && minmaxdata) {
        if (minmaxdata.min) {
            const mindate = new Date(range.from.getTime() + ((minmaxdata.min - 1) * 86400000));
            const maxdate = new Date(range.from.getTime() + ((minmaxdata.max - 1) * 86400000));

            if (day.getTime() < mindate.getTime()) {
                classnames.push(styles.beforeMin);
            }

            if (day.getTime() > maxdate.getTime()) {
                classnames.push(styles.afterMax);
            }
        }
    }

    if ((price === 0 && day.getTime() !== closeDeparture?.getTime()) && mode === "to") {
        classnames.push(styles.departureDisabled)
    }

    // День заезда
    if (day.getTime() === range.from?.getTime()) {
        classnames.push(styles.from);
    }

    // День выезда
    if (day.getTime() === range.to?.getTime()) {
        classnames.push(styles.to);
    }

    if (mode === "to" && day.getTime() < closeDeparture?.getTime() && day.getTime() < range.from.getTime()) {
        classnames.push(styles.beforeTo)
    }

    // Дни в периоде проживания
    if (day.getTime() > range.from?.getTime() && day.getTime() < range.to?.getTime()) {
        classnames.push(styles.inRange);
    }

    // При наведении
    if (day.getTime() < hovered?.getTime() && range.from?.getTime() < day.getTime() && mode === "to") {
        classnames.push(styles.hovered)
    }

    // Загрузка
    if (!uploadedMonth.has(format(new Date(new Date(day).setDate(1)), "yyyy-MM-dd")) && !isTourCalendar) {
        return <button type="button" className={classNames(styles.day, styles.disabled, styles.loading)}><SpinnerIcon /></button>
    }

    // Недоступный для выезда день
    if (mode === "to" && day.getTime() > closeDeparture?.getTime() && price) {
        return <button type="button" className={classNames(...classnames, styles.day, styles.departureDisabled)} onClick={() => clickHandler(day)} onMouseOut={() => setInfoText(null)} onMouseOver={() => mouseOverHandler(<div style={{ color: "#ff0000" }}>Возможность выезда в эту дату отсутствует</div>)}>{format(day, "d")}{price !== 0 ? <span>{formatPrice(price)} ₽</span> : <NotAvailableIcon />}</button>
    }

    // Цена / остаток 0 (Недоступен для выезда, нельзя перескочить)
    if ((price === 0 && day.getTime() !== closeDeparture?.getTime())) {
        return <button type="button" className={classNames(styles.day, styles.not_available, ...classnames)} onClick={() => clickHandler(day)} onMouseOver={() => mouseOverHandler()} onMouseOut={() => setInfoText(null)}>
            {format(day, "d")}
            <NotAvailableIcon />
        </button>
    }

    // Закрытый для заезда и выезда
    if (closedForArrival.includes(format(day, "yyyy-MM-dd")) && closedForDeparture.includes(format(day, "yyyy-MM-dd")) && price !== undefined) {
        return <button type="button" className={classNames(...classnames, styles.day, styles.closedFor)} onClick={() => clickHandler(day)} onMouseOut={() => setInfoText(null)} onMouseOver={() => mouseOverHandler(<div style={{ color: "#ff0000" }}>Возможность заезда и выезда в эту дату отсутствует</div>)}>{format(day, "d")}<div className={styles.popover} data-text="Возможность заезда и выезда в эту дату отсутствует">Возможность заезда и выезда в эту дату отсутствует</div><InfoCircledIcon /></button>
    }

    // Закрытый для заезда
    if (closedForArrival.includes(format(day, "yyyy-MM-dd")) && mode === "from" && price !== undefined) {
        return <button type="button" className={classNames(...classnames, styles.day, styles.closedFor)} onClick={() => clickHandler(day)} onMouseOut={() => setInfoText(null)} onMouseOver={() => mouseOverHandler(<div style={{ color: "#ff0000" }}>Возможность заезда в эту дату отсутствует</div>)}>{format(day, "d")}<div className={styles.popover} data-text="Возможность заезда в эту дату отсутствует">Возможность заезда в эту дату отсутствует</div><InfoCircledIcon /></button>
    }

    // Закрытый для выезда
    if (closedForDeparture.includes(format(day, "yyyy-MM-dd")) && mode === "to" && price !== undefined) {
        return <button type="button" className={classNames(...classnames, styles.day, styles.closedFor)} onClick={() => clickHandler(day)} onMouseOut={() => setInfoText(null)} onMouseOver={() => mouseOverHandler(<div style={{ color: "#ff0000" }}>Возможность выезда в эту дату отсутствует</div>)}>{format(day, "d")}<div className={styles.popover} data-text="Возможность выезда в эту дату отсутствует">Возможность выезда в эту дату отсутствует</div><InfoCircledIcon /></button>
    }

    // Цена / остаток 0 (Доступный только для выеза, нельзя перескочить)
    if (day.getTime() === closeDeparture?.getTime() && mode === "to") {
        return <button type="button" className={classNames(styles.day, ...classnames, smallestPrice === price ? styles.cheapest : "")} onClick={() => clickHandler(day)} onMouseOver={() => mouseOverHandler()} onMouseOut={() => setInfoText(null)}>
            {format(day, "d")}
        </button>
    }

    // Доступный для въезда и выезда
    if (price) {
        return <button type="button" className={classNames(styles.day, ...classnames, smallestPrice === price ? styles.cheapest : "")} onClick={() => clickHandler(day)} onMouseOver={() => mouseOverHandler()} onMouseOut={() => setInfoText(null)}>
            {format(day, "d")}
            {price !== 0 && <span>{formatPrice(price)} ₽</span>}
        </button>
    }

    // Доступный для въезда и выезда
    if (price && !isTourCalendar) {
        return <button type="button" className={classNames(styles.day, ...classnames, smallestPrice === price ? styles.cheapest : "")} onClick={() => clickHandler(day)} onMouseOver={() => mouseOverHandler()} onMouseOut={() => setInfoText(null)}>
            {format(day, "d")}
            {price !== 0 && <span>{formatPrice(price)} ₽</span>}
        </button>
    }

    // Недоступный для выбора
    return <button type="button" className={classNames(styles.day, styles.disabled)}>{format(day, "d")}</button>

}